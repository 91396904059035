@import "mixins";
@import "variables";

html {
  @include scrollbar;
  font-size: $font-size-default; /* root font size */
  font-weight: 600;
  font-family: $font-default;
  background-color: $color-background;
  color: $color-text;
  touch-action: auto;
  scroll-behavior: smooth;

  &.no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    box-sizing: border-box;


    &-ios {
      position: fixed;

      body {
        position: absolute;
        width: 100%;
      }
    }
  }

  &.filters-open {
    @include media-below($bp-sm) {
      overflow: hidden;
      height: 100%;
      width: 100%;
      box-sizing: border-box;

      body {
        position: fixed;
        width: 100%;
      }
    }
  }

  &.side-menu-open {
    body {
      position: fixed;
      max-height: 100vh;
      overflow: hidden;
    }
  }
}

a {
  text-decoration: none;
}

.text-link {
  color: $color-primary;
  cursor: pointer;
  outline: none;

  &:hover {
    color: $color-primary-action;
  }
}

.text-color-secondary {
  color: $color-secondary;
}

.hide-chat {
  #chat-widget-container {
    display: none;
  }
}

.checkbox {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 26px;

  &.checkbox--toggle {
    height: 30px;

    input[type='checkbox'] + .checkbox__view {
      animation: none !important;
    }

    input[type='checkbox']:checked + .checkbox__view:after {
      background-color: $color-primary;
      left: 24px;
    }

    .checkbox__view {
      width: 45px;
      height: 23px;
      border-radius: 20px;
      background-color: $color-background-input;
      border: 0;
      position: relative;
      display: block;

      &:after {
        display: block;
        width: 17px;
        height: 17px;
        background-color: $color-text;
        position: absolute;
        content: '';
        top: 3px;
        left: 3px;
        border-radius: 50%;
        transition: 0.2s;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:checked {
      & + .checkbox__view {
        animation: checkboxCheck 0.35s forwards;
      }
    }

    &:not(:checked) {
      & + .checkbox__view {
        animation: checkboxUncheck 0.2s forwards;
      }
    }
  }

  &__view {
    position: absolute;
    border: 2px solid $color-text;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__label {
    @include font(14px, 400);
    display: block;
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    color: $color-text;
    user-select: none;
    line-height: 1.5;
  }
}

[hidden] {
  display: none !important;
}

.center {
  text-align: center;
}

.link {
  color: $color-primary;
  text-decoration: none;
  transition: 0.1s;
  cursor: pointer;

  &:hover {
    color: lighten($color-primary, 10);
  }

  &--secondary {
    color: $color-secondary;
    border-bottom-color: $color-secondary;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}

.container {
  width: 1487px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: auto;

  @include media-below($bp-sm) {
    padding: 0 10px;
  }
}

#devcode_popup .outer-container .close {
  color: #000;
}

// FORM CONTROL

.input-container {

  .btn {
    width: 100%;
  }
}

input[type="password"]::-webkit-caps-lock-indicator {
  content: none;
}

.input {
  outline: none;
  cursor: pointer;
  background: transparent;
  color: $color-text;
  border-radius: 10px;
  box-sizing: border-box;
  height: 44px;
  border: 2px solid transparent;
  width: 100%;

  @include autofill() {
    caret-color: #0d0827;
  }

  @include ngError {
    border-color: $color-error;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $color-text;
  }

  &--simple {
    @include font(16px, 500);
    transition: 0.2s;
    padding: 0 30px;
    background-color: $color-background-input;

    &:focus {
      background-color: $color-background-input;
    }
  }

  &--outline {
    border: 2px solid $color-primary;
    color: $color-text-light;
    border-radius: 50px;
  }
}

textarea.input--simple {
  padding-top: 25px;
}

.file-loader {
  display: block;
  padding: 30px 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #553d67;
  background-color: #250241;

  @include media-below($bp-sm) {
    padding: 20px 15px;
  }

  input[type='file'] {
    display: none;
  }

  &__label {
    @include font(14px);

    @include media-below($bp-sm) {
      @include font(12px);
    }

    .link {
      @include font(14px, 500);

      @include media-below($bp-sm) {
        @include font(12px);
      }
    }
  }
}

.file-over {
  .file-loader {
    border-color: #fff;
  }
}


.form-control {

  &--container {
    display: flex;
    justify-content: space-between;

    app-form-input {
      width: 100%;

      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
}

.select {
  @include font(14px, 500);
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: #000;

  @include ngError {
    .select__header {
      color: $color-error;
    }
  }

  &__header {
    @include font(16px);
    display: block;
    line-height: 44px;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $color-background-input;
    color: $color-text;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    padding: 0 45px 0 16px;
    border-radius: 10px;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      border: 1px solid;
      border-top-width: 0;
      border-left-width: 0;
      transform: rotateZ(45deg) translateX(-82%);
      right: 13px;
      bottom: 7px;
    }

    &:before {
      display: block;
      position: absolute;
      content: '';
      border-left: 1px solid;
      height: 22px;
      top: 11px;
      right: 19px;
    }
  }

  &__dropdown {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%) scale(0.9, 0);
    width: 100%;
    transition: 0.2s;
    opacity: 0;
    padding: 5px 0;
    border-radius: 8px;
    background-color: #44446B;
    color: #fff;
    overflow: hidden;
    transform-origin: center;
    box-shadow: 0 15px 0 -16px #000;
    z-index: 11;

    &-container {
      @include scrollbar;
      overflow: auto;
      max-height: 180px;

      &::-webkit-scrollbar {
        background-color: darken(#44446B, 10);
      }
    }

    &.open {
      visibility: visible;
      transform: translateY(-50%) scale(1, 1);
      opacity: 1;
      pointer-events: all;
      box-shadow: 0 15px 18px -16px #000;
    }
  }

  &__option {
    @include font(14px, 600);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 15px;
    transition: 0.1s;
    outline: none;

    &:hover {
      background-color: darken(#44446B, 10);
    }
  }
}


.text {
  h1 {
    font-size: 18px;
    margin: 0;
  }
}


.password-toggle {

  &.active {
    color: $color-secondary;
  }
}


.error-tooltip {
  @include font(12px, 500);
  color: $color-primary;
  position: fixed;
  background-color: $color-background;
  border-radius: 6px;
  padding: 15px;
  max-width: 170px;
  user-select: none;
  opacity: 0;
  transition: opacity 0.3s, transform 0.2s;
  z-index: 9999;
  pointer-events: none;

  &:not(.right) {
    transform: translateY(20px);

    &:after {
      left: 20px;
      top: 100%;
    }
  }

  &.right {
    transform: translateX(-20px);
  }


  &.visible {
    transform: scale(1) translateX(0px) translateY(0px);
    opacity: 1;
    pointer-events: all;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: $color-background;
    width: 10px;
    height: 10px;
    transform: rotateZ(45deg) translateY(-60%);
    left: -8px;
    top: 50%;
  }

  &--informer {
    &:not(.right) {
      &:after {
        left: auto;
        right: 20px;
        top: 100%;
      }
    }
  }
}

.section-title {
  @include font(50px, 700);
  font-family: $font-additional;
  text-transform: uppercase;
  color: $color-secondary;
  text-align: center;
  margin-bottom: 40px;
  position: relative;

  &:after {
    position: absolute;
    right: 100%;
    top: 0;
    width: 20px;
    height: 100%;
    border-radius: 0 20px;
    background: #CC00FF;
    display: none;
    content: '';

    @include media-below($bp-sm) {
      width: 10px;
    }
  }

  &--line-desktop {
    text-align: left;
    font-size: 30px;
    padding: 10px 0 10px 40px;
    margin-bottom: 0;

    @include media-above($bp-sm) {
      &:after {
        display: block;
        right: unset;
        left: 0;
      }
    }

    @include media-below($bp-sm) {
      font-size: 16px;
      padding: 5px 0 5px 10px;
    }
  }

  @include media-below($bp-ld) {
    @include font(32px);
  }

  @include media-below($bp-sm) {
    @include font(28px, 700);
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    text-align: left;
    padding-left: 20px;

    &:after {
      display: block;
    }
  }
}

.section-subtitle {
  @include font(23px, 600);
  margin-bottom: 40px;
  color: $color-secondary;
  text-align: center;
  line-height: 165%;

  @include media-below($bp-ld) {
    @include font(18px);
  }

  @include media-below($bp-sm) {
    @include font(14px);
    margin-bottom: 20px;
  }
}


@keyframes preloading {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 200%;
  }
  100% {
    background-position: 200%;
  }
}


.preloading {
  height: 320px;
  background: linear-gradient(
      -45deg,
      $color-dark 0% 30%,
      $color-background-light 30% 31%,
      $color-dark 31% 33%,
      $color-background-light 33% 37%,
      $color-dark 37% 100%,
  ) repeat-x 0;
  animation: preloading 2s infinite linear;
  background-size: 150%;

  @include media-below($bp-md) {
    height: 232px;
  }

  @include media-below($bp-sm) {
    height: 169px;
  }
}
