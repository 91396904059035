@font-face {
  font-family: 'Permanent-marker';
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/Permanent-marker.ttf') format('truetype');
}

@font-face {
  font-family: 'Mont';
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/Mont-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Grotters';
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/Grotters.ttf') format('truetype');
}
