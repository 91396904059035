@import "normalize";
@import "./../node_modules/ng-zorro-antd/slider/style/index.min.css";
@import "variables";
@import "mixins";

@import "fonts";
@import "icons";
@import "animations";
@import "common";

