@font-face {
  font-family: "icons1735210596483";
  font-display: swap;
  src: url('/assets/fonts/icons1735210596483.eot');
  src: url('/assets/fonts/icons1735210596483.eot?#iefix') format('eot'),
  url('/assets/fonts/icons1735210596483.woff') format('woff'),
  url('/assets/fonts/icons1735210596483.ttf') format('truetype')
}

@mixin icon-styles {
  font-family: "icons1735210596483";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == 1-game {
  $char: "\E001";
}
  @if $filename == 2-games {
  $char: "\E002";
}
  @if $filename == 4-games {
  $char: "\E003";
}
  @if $filename == 777 {
  $char: "\E004";
}
  @if $filename == account {
  $char: "\E005";
}
  @if $filename == arrow {
  $char: "\E006";
}
  @if $filename == back {
  $char: "\E007";
}
  @if $filename == balance {
  $char: "\E008";
}
  @if $filename == black-jack {
  $char: "\E009";
}
  @if $filename == bonuses {
  $char: "\E00A";
}
  @if $filename == burger {
  $char: "\E00B";
}
  @if $filename == chat {
  $char: "\E00C";
}
  @if $filename == check {
  $char: "\E00D";
}
  @if $filename == close {
  $char: "\E00E";
}
  @if $filename == coins {
  $char: "\E00F";
}
  @if $filename == copy-code {
  $char: "\E010";
}
  @if $filename == copy {
  $char: "\E011";
}
  @if $filename == diamond {
  $char: "\E012";
}
  @if $filename == documents {
  $char: "\E013";
}
  @if $filename == edit {
  $char: "\E014";
}
  @if $filename == error {
  $char: "\E015";
}
  @if $filename == exclamation {
  $char: "\E016";
}
  @if $filename == facebook {
  $char: "\E017";
}
  @if $filename == favorite {
  $char: "\E018";
}
  @if $filename == favourite {
  $char: "\E019";
}
  @if $filename == favourite_hover {
  $char: "\E01A";
}
  @if $filename == filters {
  $char: "\E01B";
}
  @if $filename == free-spin {
  $char: "\E01C";
}
  @if $filename == fullscreen {
  $char: "\E01D";
}
  @if $filename == gif {
  $char: "\E01E";
}
  @if $filename == gift {
  $char: "\E01F";
}
  @if $filename == history {
  $char: "\E020";
}
  @if $filename == hot {
  $char: "\E021";
}
  @if $filename == information {
  $char: "\E022";
}
  @if $filename == last-played {
  $char: "\E023";
}
  @if $filename == like {
  $char: "\E024";
}
  @if $filename == like_filled {
  $char: "\E025";
}
  @if $filename == list {
  $char: "\E026";
}
  @if $filename == live-casino {
  $char: "\E027";
}
  @if $filename == lobby {
  $char: "\E028";
}
  @if $filename == location {
  $char: "\E029";
}
  @if $filename == lock {
  $char: "\E02A";
}
  @if $filename == logout {
  $char: "\E02B";
}
  @if $filename == mail {
  $char: "\E02C";
}
  @if $filename == new {
  $char: "\E02D";
}
  @if $filename == next {
  $char: "\E02E";
}
  @if $filename == notificationmob {
  $char: "\E02F";
}
  @if $filename == notify {
  $char: "\E030";
}
  @if $filename == offers {
  $char: "\E031";
}
  @if $filename == password {
  $char: "\E032";
}
  @if $filename == pencil {
  $char: "\E033";
}
  @if $filename == play {
  $char: "\E034";
}
  @if $filename == plus {
  $char: "\E035";
}
  @if $filename == pointer-down {
  $char: "\E036";
}
  @if $filename == question {
  $char: "\E037";
}
  @if $filename == reload {
  $char: "\E038";
}
  @if $filename == responsible-gaming {
  $char: "\E039";
}
  @if $filename == rg {
  $char: "\E03A";
}
  @if $filename == roulette {
  $char: "\E03B";
}
  @if $filename == search {
  $char: "\E03C";
}
  @if $filename == searchmob {
  $char: "\E03D";
}
  @if $filename == settings {
  $char: "\E03E";
}
  @if $filename == shield {
  $char: "\E03F";
}
  @if $filename == slider-arrow-left {
  $char: "\E040";
}
  @if $filename == slider-arrow-right {
  $char: "\E041";
}
  @if $filename == slot {
  $char: "\E042";
}
  @if $filename == slotmachine {
  $char: "\E043";
}
  @if $filename == slots {
  $char: "\E044";
}
  @if $filename == star {
  $char: "\E045";
}
  @if $filename == support {
  $char: "\E046";
}
  @if $filename == table-games {
  $char: "\E047";
}
  @if $filename == text {
  $char: "\E048";
}
  @if $filename == time {
  $char: "\E049";
}
  @if $filename == title-line {
  $char: "\E04A";
}
  @if $filename == tournaments {
  $char: "\E04B";
}
  @if $filename == trash {
  $char: "\E04C";
}
  @if $filename == twitter {
  $char: "\E04D";
}
  @if $filename == user {
  $char: "\E04E";
}
  @if $filename == video-poker {
  $char: "\E04F";
}
  @if $filename == video {
  $char: "\E050";
}
  @if $filename == vip {
  $char: "\E051";
}
  @if $filename == wallet {
  $char: "\E052";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-1-game {
  @include icon(1-game);
}
.icon-2-games {
  @include icon(2-games);
}
.icon-4-games {
  @include icon(4-games);
}
.icon-777 {
  @include icon(777);
}
.icon-account {
  @include icon(account);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-back {
  @include icon(back);
}
.icon-balance {
  @include icon(balance);
}
.icon-black-jack {
  @include icon(black-jack);
}
.icon-bonuses {
  @include icon(bonuses);
}
.icon-burger {
  @include icon(burger);
}
.icon-chat {
  @include icon(chat);
}
.icon-check {
  @include icon(check);
}
.icon-close {
  @include icon(close);
}
.icon-coins {
  @include icon(coins);
}
.icon-copy-code {
  @include icon(copy-code);
}
.icon-copy {
  @include icon(copy);
}
.icon-diamond {
  @include icon(diamond);
}
.icon-documents {
  @include icon(documents);
}
.icon-edit {
  @include icon(edit);
}
.icon-error {
  @include icon(error);
}
.icon-exclamation {
  @include icon(exclamation);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-favorite {
  @include icon(favorite);
}
.icon-favourite {
  @include icon(favourite);
}
.icon-favourite_hover {
  @include icon(favourite_hover);
}
.icon-filters {
  @include icon(filters);
}
.icon-free-spin {
  @include icon(free-spin);
}
.icon-fullscreen {
  @include icon(fullscreen);
}
.icon-gif {
  @include icon(gif);
}
.icon-gift {
  @include icon(gift);
}
.icon-history {
  @include icon(history);
}
.icon-hot {
  @include icon(hot);
}
.icon-information {
  @include icon(information);
}
.icon-last-played {
  @include icon(last-played);
}
.icon-like {
  @include icon(like);
}
.icon-like_filled {
  @include icon(like_filled);
}
.icon-list {
  @include icon(list);
}
.icon-live-casino {
  @include icon(live-casino);
}
.icon-lobby {
  @include icon(lobby);
}
.icon-location {
  @include icon(location);
}
.icon-lock {
  @include icon(lock);
}
.icon-logout {
  @include icon(logout);
}
.icon-mail {
  @include icon(mail);
}
.icon-new {
  @include icon(new);
}
.icon-next {
  @include icon(next);
}
.icon-notificationmob {
  @include icon(notificationmob);
}
.icon-notify {
  @include icon(notify);
}
.icon-offers {
  @include icon(offers);
}
.icon-password {
  @include icon(password);
}
.icon-pencil {
  @include icon(pencil);
}
.icon-play {
  @include icon(play);
}
.icon-plus {
  @include icon(plus);
}
.icon-pointer-down {
  @include icon(pointer-down);
}
.icon-question {
  @include icon(question);
}
.icon-reload {
  @include icon(reload);
}
.icon-responsible-gaming {
  @include icon(responsible-gaming);
}
.icon-rg {
  @include icon(rg);
}
.icon-roulette {
  @include icon(roulette);
}
.icon-search {
  @include icon(search);
}
.icon-searchmob {
  @include icon(searchmob);
}
.icon-settings {
  @include icon(settings);
}
.icon-shield {
  @include icon(shield);
}
.icon-slider-arrow-left {
  @include icon(slider-arrow-left);
}
.icon-slider-arrow-right {
  @include icon(slider-arrow-right);
}
.icon-slot {
  @include icon(slot);
}
.icon-slotmachine {
  @include icon(slotmachine);
}
.icon-slots {
  @include icon(slots);
}
.icon-star {
  @include icon(star);
}
.icon-support {
  @include icon(support);
}
.icon-table-games {
  @include icon(table-games);
}
.icon-text {
  @include icon(text);
}
.icon-time {
  @include icon(time);
}
.icon-title-line {
  @include icon(title-line);
}
.icon-tournaments {
  @include icon(tournaments);
}
.icon-trash {
  @include icon(trash);
}
.icon-twitter {
  @include icon(twitter);
}
.icon-user {
  @include icon(user);
}
.icon-video-poker {
  @include icon(video-poker);
}
.icon-video {
  @include icon(video);
}
.icon-vip {
  @include icon(vip);
}
.icon-wallet {
  @include icon(wallet);
}