
// colors
$color-primary: #D3FF06;
$color-primary-action: lighten($color-primary, 10);

$color-secondary: #ffffff;
$color-secondary-action: #FE4B33;

$color-tertiary: #CC00FF;

$color-focus: #FE4B33;
$color-hover: #7053E4;

$color-background: #00002B;
$color-background-light: #3A207B;
$color-background-payment-logo: #00002B;
$color-background-input: #252543;
$color-preloading: $color-background-input;

$color-light: darken(#fff, 30);
$color-dark: #00002B;

$color-text: #84799F;
$color-text-light: #84799F;

$color-error: #f20000;
$color-success: #4BB543;

// fonts
$font-default: "Gilroy";
$font-additional: "Montserrat";
$font-additional-secondary: "Permanent-marker";
$font-additional-tertiary: "Mont";

// font size
$font-size-default: 16px;

// transitions
$transition-time-default: 0.2s;

// breakpoints
$bp-xs-ios: 340px;
$bp-iphone-6-8: 376px;
$bp-xs: 536px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-ld: 1200px;
$bp-xl: 1500px;
